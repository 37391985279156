@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';

@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.css';

@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

@font-face {
  font-family: "Avenir-Roman";
  src: url("/fonts/Avenir/Avenir-Roman.ttf");
}

@font-face {
  font-family: "Avenir-Heavy";
  src: url("/fonts/Avenir/Avenir-Heavy.ttf");
}

// Note: Requested to override the automatic color choosing of bootstrap for the secondary color:
.btn-secondary {
  color: white !important;
}

hr {
  height: 2px;
  border: none;
  color:$gray-400;
  background-color:$gray-400;
}
.form-rounded {
  border-radius: 1rem;
}

.paper {
  padding: 1.5em;
  background-color: white;
}

.more-info-wrap {
  div {
    font-size: 15px;
  }
}

.details-table {
  th, td {
    border: none !important;
  }
}

.date-range-selector {
  border: none;
  background: none;
  outline: none !important;
  padding: 0;

  span {
    border-bottom: 1px solid $gray-600;
  }

  svg, .dropdown-toggle::after {
    color: $gray-600;
  }
}

.spinner-border {
  margin: auto;
  display: block;
}

.text-gray {
  color: $gray-700;
}

.pet-bg {
  background-color: $teal;
  
  background-image: url('/ruby-schmank-r-pc-4-qna-pq-q-0-unsplash@2x.jpg');
  background-position: center;
  background-size: cover;
}

.user-toplevel-dropdown {
  width: 100px;
  height: 100px;

  > button {
    padding: 6px;
    display: block;
    height: 100%;
    width: 100%;

    &:hover, &:focus {
      box-shadow: none;
    }
  }

  .dropdown-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    
    display: block;
    width: 100%;
    height: 100%;

    border: 1px solid $gray-400;
    border-radius: 50%;
  }
  
  .dropdown-menu {
    min-width: 210px;
  }

  .dropdown-menu, .dropdown-item, .dropdown-header {
    cursor: pointer;
    background-color: $teal;
    color: white;
  }

  a, .btn-link {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
}

// Search bar on table
.search-label {
  margin: 0 2rem 0 0;
}

.text-small {
  font-size: 0.85rem;
}

.chart-box {
  width: 100%;

  .chart {
    height: 300px;
    width: 75%;
  }
  .legend {
    width: 25%;
  }
  .view-dot {
    height: 15px;
    width: 15px;
    background-color: #01016f;
    border-radius: 50%;
    display: inline-block;
  }
  .claim-dot {
    height: 15px;
    width: 15px;
    background-color: #d8031c;
    border-radius: 50%;
    display: inline-block;
  }
  .view-stat {
    color: #01bfa5;
    font-weight: bold;
  }
  .claim-stat {
    color: #01bfa5;
    font-weight: bold;
  }
  .chart-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .chart-legend-list {
    list-style: none;
    padding-inline-start: 0;
  }
}

.popover {
  max-width: none;
  border-radius: 0;
}

.table th, .table td {
  padding-left: 0;
}

.offer-analytics-link {
  color: blue;
  text-decoration: underline;
  width: 70px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.brand-logo {
  max-width: 100px;
}

// .dropzone-logo {
//   width: 180px;
// }

.dropzone-wrap {
  width: 100%;
  position: relative;

  .dropzone {
    .dropzone-message {
      justify-content: center;
      align-items: center;
      color: $teal;
    }
  }
}
.dropzone-wrap.full {
  
  padding-bottom: 56.25%;
  // 1:1	100%, 16:9	56.25%, 4:3	75%, 3:2	66.66%, 8:5	62.5%

  .dropzone {
    width: 100%;
    height: 100%;
    position: absolute;
  
    // border: 1px solid #dedede;
    background: #f8f8f8;
  
    text-align: center;
    
    // Is for changing the image:
    .dropzone-change div {
        width: 100%;
        
        background-color: rgba($teal, .5);
        border-radius: 50%;
    
        margin: auto;
        width: 100px;
        height: 100px;

        display: flex;
        flex-direction: column;
    
        justify-content: center;
        align-items: center;
        color: white;
    }
  }
}

.dropzone-wrap.compact {
  height: 150px;

  .dropzone {
    width: 50%;
    height: 100%;
    // background: #f8f8f8;
    text-align: center;
    float: left;
  }
  .change-link {
    
    float: left;
    padding-left: 1.5em;
    height: 150px;
    margin: auto;
    display: flex;
    align-items: center;

    button {
      color: $teal;
      text-decoration: none;
    }
  }
}

.react-datetime-picker__calendar {
  z-index: 10 !important;
}
.react-datetime-picker__wrapper {
  border: 0 !important;
  flex-grow: 1;
}
.react-datetime-picker {
  width: 100%;
}

.rich-text-editor {
  min-height: 280px;

  display: flex;
  flex-direction: column;

  .editor-box {
    flex-grow: 1;
    overflow-x: auto;
  }
}

.btn-focal {
  border-radius: 2em;
}

.btn-rounded {
  border-radius: 2em;
}

.brand-missing {
  .alert {
    margin: 5em auto;
    width: 400px;
    text-align: center;
  }
}

.nav-link {
  // text-decoration: none;

  // @include hover-focus {
  //   text-decoration: underline;
  // }
  color: black;

  &.active {
    color: $primary;
  }
}

// -------------------------  TAGS INPUT  ---------------------------------
// TODO: Clean the tag styling

div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  padding: 3px;
  display: inline-block;

  
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  margin: 0;
  width: 100%;
  padding: 2px 4px;

  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  
  display: inline-block;
  padding: 6px;
  margin: 0 5px 0 0;

  cursor: default !important;
  
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}
div.ReactTags__selected a.ReactTags__remove {
  color: #9c9c9c;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
  color: #000 !important;
  font-weight: normal !important;
  font-size: 14px !important;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}